
.bg-dark {
    background-color: #ea5e5e !important;
}

.sidebar {
    background-color: #ea5e5e!important;
}

.sidebar .nav-item .nav-link span {
    color:white !important;
    font-weight: bold;
 }
 .sidebar .nav-item .nav-link {
    color:white !important;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #c32323;
    border-color: white;
}
.navbar-dark .navbar-nav .nav-link {
    color:white !important;
}
.btn-primary {
    color: #fff;
    background-color: #c32323;
    border-color: white;
    border-radius: 28px;
}
.btn-primary:hover {
    color: #fff;
    background-color: #f90d0d;
    border-color: whitesmoke;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #ea5e5e;
    border-radius: 0.25rem;
}
.breadcrumb-item.active {
    color: white;
    font-weight: bold;
}
.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: white;
    content: "/";
}
ol.breadcrumb a {
    color: white;
}
.btn-outline-secondary {
    color: white;
    border-color: white;
}
.btn-link {
    color: white;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #e50505;
}
.btn-primary.focus, .btn-primary:focus {
    color: #fff;
    background-color: #e50505;
    border-color: white;
    box-shadow: 0 0 0 0.2rem #e50505;
}
.scroll-to-top,.scroll-to-top-hidemenu{
    background-color: #ffa5a5bf;
}
.scroll-to-top:hover,.scroll-to-top-hidemenu:hover {
    background: #d73939d1;
}

/* Zebra striping */
table tr:nth-of-type(odd) {
    background: #cb5c5c;
}
table tr:nth-of-type(even) {
    background: #db7171;
}
table th {
    background: #b53e3e;
    color: white;
    font-weight: bold;
}
/*legend*/
legend.section-legend-border {
    width: inherit;
    padding: 0 10px;
    border-bottom: none;
    background: #ff7e7e;
    border-radius: 23px;
    color: white;
  }
  fieldset.section-legend-border {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    box-shadow: 0px 0px 0px 0px #000;
    width: auto;
    background-color: #df0808a6;
    color: white;
    font-weight: bold;
    border-radius: 32px;
  }
  
  legend.section-legend-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
  }
  .sub-section-legend {
    /* background-color: #ea5e5e !important; */
    background-color: #d95555 !important;
}
/*Product Details Page*/
  .section-income-heading-div {
    background-color: #951818;
    
}
/*habit section*/
.habit-log-row{
    background: #ea5e5e;
    color: white;
}
.btn{
    color: white !important;
}
.span-menu{
    background-color:#ea5e5e !important ;
}
.activity-type-item span {
    background: #ea5e5e;
}

span.active {
    background: #7F3535 !important;
}

.round-div{
    border-radius: 32px !important;
}
/*Product*/
.activeButton {
    background-color: #ff0000;;
    border-color: white;
}
/*Interview Question*/
#topic-list span, #subject-list span{ background-color: #ea5e5e;;
    border-color: white;
    color: white;
}